<template>
   <div class="card mb-1" :class="isSelecionado ? 'border-theme' : ''">
      <div class="card-body p-12">
         <div class="row m-0 align-items-center">
            <div class="col px-1 product-group-left text-center text-xl-start">
               <h3 class="limitador weight-600 color-theme mb-0">
                  <a href="javascript:;" @click="verFoto">
                     <i v-if="isSelecionado" class="fas fa-angle-right font-12 color-theme me-2"></i>{{ variacao.nome }}
                  </a>
               </h3>
               <span class="d-block weight-600 mb-1">Cód. {{ String(variacao.codigo).substring(0, 2) +"-"+ String(variacao.codigo).substring(2, String(variacao.codigo).length) }}</span>
               <span class="d-block limitador">{{ variacao.descricao == null ? 'Sem descrição' : variacao.descricao }}</span>
            </div>
            <div class="wpx-100 px-1 text-center mx-auto mx-md-none mt-2 mt-xl-0" v-if="variacao.lancamento == 'S'">
               <span class="badge bg-theme d-none d-xl-inline-block font-11">Lançamento</span>
               <i class="far fa-bell-on color-theme d-inline d-xl-none font-16"></i>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Variacao',
   props: ['variacao', 'fotosSelecionadas'],
   data: function () {
      return {
         isSelecionado: false
      }
   },
   watch: {
      fotosSelecionadas : function (value) {
         this.isSelecionado = this.variacao.fotos.length > 0 && JSON.stringify(value) == JSON.stringify(this.variacao.fotos);
      }
   },
   methods: {
      verFoto : function () {
         if (this.variacao.fotos.length > 0) {
            this.$emit('verFotos', this.variacao.fotos)
         }
      }
   },
   mounted() {
      this.isSelecionado = this.variacao.fotos.length > 0 && JSON.stringify(this.fotosSelecionadas) == JSON.stringify(this.variacao.fotos);
   }
}

</script>

<style scoped>

.product-group-left {
   line-height: 1.5;
}

</style>