<template>
   <tr v-if="view == 'Especificacoes'" class="row" :class="index % 2 == 0 ? 'bg-secondary' : ''">
      <td>{{ especificacao.rotulo }}</td>
      <td class="col weight-300" v-for="(valor, index) in especificacao.valores" :key="index">{{ valor }}</td>
   </tr>
   <tr v-else-if="view == 'Compativeis'" class="row" :class="index % 2 == 0 ? 'bg-secondary' : ''">
      <td>Cód. {{ especificacao.codigo == null ? '-' : especificacao.codigo }}</td>
      <td class="col weight-300">{{ especificacao.nome == null ? '-' : especificacao.nome }}</td>
   </tr>
</template>

<script>

export default {
   name: 'Especificacao',
   props: ['especificacao', 'index', 'view']
}

</script>

<style scoped>

.row {
   margin: 0;
   flex-wrap: nowrap;
}

td {
   padding: 8px 12px !important;
   border: none !important;
   border-right: 1px solid #d6dce1 !important;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14.5px !important;
   min-width: 250px;
}

td:first-child {
   width: 185px;
   min-width: 185px;
   justify-content: start;
   text-align: start;
}

@media (min-width: 768px) {
   td {
      padding: 8px 24px !important;
   }
   
   td:first-child {
      width: 250px;
      min-width: 250px;
   }
}

td:last-child {
   border-right: none !important
}

</style>