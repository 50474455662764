<template>
   <li v-if="location == 'HEADER'" class="nav-item pe-1 pe-sm-0" role="presentation">
      <button :id="'pills-tab-'+ index + container" :data-bs-target="'#pills-'+ index + container" :aria-controls="'pills-'+ index + container" :codigo="foto.codigo"
         :class="[index == 0 ? 'active' : '', foto.tipo == 'Vídeo' ? 'container-video' : 'container-img']" :aria-selected="index == 0 ? true : false"
         class="nav-link ratio ratio-1x1 mb-0" type="button" role="tab" data-bs-toggle="pill">
         <img :src="foto.url == null ? '' : foto.url" @error="imageError">
      </button>
   </li>

   <div v-else :class="index == 0 ? 'show active' : ''" :id="'pills-'+ index + container" :aria-labelledby="'pills-tab-'+ index + container" 
      class="tab-pane fade position-relative" role="tabpanel" :main-codigo="foto.codigo">
      <div class="product__details-nav-thumb ratio ratio-1x1" :class="foto.tipo == 'Vídeo' ? 'container-video' : 'container-zoom'" @contextmenu.prevent="panzoom.instance.pause()" @click="panzoom.instance.resume()">
         <panZoom :options="{bounds: true, maxZoom: 2.5, minZoom: 0.3, initialZoom: 1, transformOrigin: null}" @init="onInitPanZoom">
            <img :src="foto.url == null ? '' : foto.url" @error="imageError" ref="imgZoom" class="w-100 h-100" />
         </panZoom>
      </div>
      <button class="btn btn-zoomExpand px-3" @click="visualizar(null)" v-if="panzoom.transform != 'none'"><i class="fal fa-expand-arrows"></i></button>
      <button class="btn btn-zoomReset px-3" @click="zoomPanZoom(null)" v-if="panzoom.transform != 'none'"><i class="fal fa-expand-wide"></i></button>
      <button class="btn btn-zoomIn px-3" @click="zoomPanZoom(true)" v-if="panzoom.instance != null"><i class="fal fa-search-plus"></i></button>
      <button class="btn btn-zoomOut px-3" @click="zoomPanZoom(false)" v-if="panzoom.instance != null"><i class="fal fa-search-minus"></i></button>
   </div>
</template>

<script>

import imgError from '@/assets/cliente/img/error.png'

export default {
   name: 'Foto',
   props: ['foto', 'index', 'location', 'container'],
   data : function () {
      return {
         imgError: imgError,
         panzoom: {'instance': null, 'transform': null}
      }
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      visualizar : function () {
         // if (this.foto.tipo == 'video') {
            this.$emit('visualizar', this.foto)
         // }
      },
      onInitPanZoom : function (panzoomInstance) {
         setTimeout(() => {
            this.panzoom = {'instance': panzoomInstance, 'transform': JSON.parse(JSON.stringify(panzoomInstance.getTransform()))}
            this.$refs.imgZoom.style.transform = 'none'
            panzoomInstance.pause()
         }, 250);
      },
      zoomPanZoom : function (zoomIn) {
         let config = this.panzoom.instance.getTransform()
         
         if (zoomIn == null) {
            this.panzoom.instance.moveTo(this.panzoom.transform.x, this.panzoom.transform.y)
            this.panzoom.instance.zoomAbs(this.panzoom.transform.x, this.panzoom.transform.y, 1)
            setTimeout(() => this.$refs.imgZoom.style.transform = 'none', 250);
            
         } else if (zoomIn == true) {
            this.panzoom.instance.smoothZoom(config.x, config.y, 1.5);

         } else if (zoomIn == false) {
            this.panzoom.instance.smoothZoom(config.x, config.y, 0.5);
         }
      }
   }
}

</script>

<style scoped>

li img {
   object-fit: cover;
}

.vue-pan-zoom-scene img {
   object-fit: contain;
   cursor: pointer;
}

li {
   width: 90px;
   min-width: 90px;
   height: 90px;
   min-height: 90px;
}

</style>